@import 'styles/vars';

.container {
  margin-top: 20px;
}

.taxes {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}

.row {
  display: flex;
  justify-content: space-between;

  font-weight: $medium;
  font-size: 12px;
  line-height: 14px;
  color: $font-color-gray;
  letter-spacing: -0.5px;

  &__title, &__price {
    margin: 4px 0 0 0;
  }

  &__price {
    color: $font-main-color;
    font-weight: $bold;
  }
}
