@import 'styles/vars';

.item {
  display: flex;
  justify-content: space-between;

  letter-spacing: -0.5px;
  font-weight: $regular;

  margin-top: 8px;
  padding-bottom: 20px;

  &__col {
    color: $font-main-color;
    max-width: 185px;
  }

  &__title, &__descr {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }

  &__options {
    font-size: 11px;
    line-height: 14px;
    color: $font-color-gray;
    margin: 10px 0 0 0;
    letter-spacing: -0.458333px;
  }

  &__total, &__price, &__quantity {
    margin: 0;
    text-transform: uppercase;
    text-align: right;
  }

  &__total {
    font-weight: $bold;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.5px;
  }

  &__price, &__quantity {
    font-weight: $regular;
    font-size: 11px;
    color: $font-color-gray;
    letter-spacing: -0.5px;
  }
}