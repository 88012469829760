@import 'styles/vars';

.method__card {

  input:checked + label {
    border: 1px solid rgba(107, 89, 237, 1);
  }

  input:checked + label:after {
    display: block;
  }

  input:checked + label:before {
    background: rgba(107, 89, 237, 1);
  }

  input:checked + label &_title {
    color: rgba(107, 89, 237, 1);
  }

  input:checked ~ .accordion_card {
    max-height: 500px;
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &_label {
    height: 60px;
    margin: 0;

    position: relative;
    padding: 0 20px 0 40px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid rgba(188, 193, 200, 1);
    border-radius: 5px;

    &::before {
      content: '';
      top: 50%;
      position: absolute;
      left: 10px;
      transform: translateY(-50%);
  
      width: 19px;
      height: 19px;
  
      background: #FFFFFF;
      border: 1px solid rgba(226, 209, 249, 1);
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.164582);
      border-radius: 100%;
    }
  
    &::after {
      content: '';
      display: none;
      top: 50%;
      position: absolute;
      left: 16px;
      transform: translateY(-50%);
  
      width: 7px;
      height: 7px;
      background: #FFFFFF;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.164582);
      border-radius: 50%;
    }
  }

  &_title {
    font-weight: $bold;
    font-size: 13px;
    line-height: 19px;

    color: #000000;
  }
}

.accordion_card {
  max-height: 0;
  overflow: hidden;

  transition: max-height 0.5s ease-in-out;
}