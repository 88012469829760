/* font-weights */
$black: 900;
$extra-bold: 800;
$bold: 700;
$demibold: 600;
$medium: 500;
$regular: 400;
$thin: 300;

/* font colors */
$font-main-color: rgba(74, 74, 74, 1);
$font-color-gray: rgba(135, 135, 135, 1);
