@import 'styles/vars';

.item {
  padding: 20px;
  margin-bottom: 20px;

  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(232, 247, 255, 1);
  box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.0803103);
  border-radius: 3px;

  color: $font-main-color;

  &__row, &__subrow {
    display: flex;
    justify-content: space-between;
  }

  &__row {

    @media (max-width: 560px) {
      font-weight: $medium;
      font-size: 12px;
      line-height: 16px;

      letter-spacing: -0.16875px;
    }
  }

  &__row_title {
  
    @media (max-width: 560px) {
      max-width: 140px;

      font-weight: $medium;
      font-size: 12px;
      line-height: 16px;

      letter-spacing: -0.16875px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; 
      -moz-box-orient: vertical;      /* Mozilla */
      overflow: hidden;
    }
  }

  &__row_price {
    @media (max-width: 560px) {
      font-weight: $demibold;

    }
  }

  &__subrow {

    @media (max-width: 560px) {
      font-size: 10px;
      line-height: 30px;
      font-weight: $regular;
      
      letter-spacing: -0.140625px;
    }
  }
}