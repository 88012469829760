@import 'styles/vars';

.navbar {
  z-index: 10000;
  position: sticky;
  height: 95px;
  top: 0;
  left: 0;
  background: var(--white);

  @media (max-width: 560px) {
    height: 60px;
    z-index: 10;
  }

  &__logolink {
    
    @media (max-width: 560px) {
      width: 63px;
      height: 23px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__container {
    padding: 0 60px;
    height: 100%;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 560px) {
      padding: 0 20px;
    }
  }

  &__dropdown {
    display: flex;
    align-items: center;

    transition: all 0.3s;

    &_btn {
      color: rgba(65, 29, 117, 1);
      font-weight: $medium;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.28125px;
    
      background-color: initial;
      border: none;
    }
    
    svg {
      margin-right: 8px;
    }

    &::after {
      content: none;
    }

    &:hover {
      opacity: 0.5;
    }

    @media (max-width: 560px) {
      &_btn {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.196875px;
      }

      svg {
        height: 20px;
        margin-right: initial;
      }
    }
  }

  &__dropdown_menu {
    margin-top: 10px;
    width: fit-content;
    background: var(--white);
    border: 1px solid gray;
    border-radius: 5px;
  }
}