@import 'styles/vars';


.quote__header {
  padding-top: 66px;
  background: linear-gradient(180deg, rgba(167, 76, 242, 1) 0%, rgba(117, 101, 251, 1) 100%), rgba(216, 216, 216, 1);

  display: flex;
  color: var(--white);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 560px) {
    padding-top: 20px;
    flex-direction: column;
    margin: 0 -20px;
  }

  &_sent {
    height: 100%;
    padding-top: 66px;
    background: linear-gradient(180deg, rgba(167, 76, 242, 1) 0%, rgba(117, 101, 251, 1) 100%), rgba(216, 216, 216, 1);

    display: flex;
    color: var(--white);

    @media (max-width: 560px) {
      padding-top: 20px;
      flex-direction: column;
    }
  }

  &_img {
    background: url('../../../assets/images/hero-image.png') no-repeat;
    background-size: contain;
    background-position: left bottom;
    position: relative;
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
      min-height: 440px;
      background-position: center;
    }

    @media (max-width: 560px) {
      min-height: 340px;
    }
  }

  &_wrapper {
    width: 45%;
    margin-top: 40px;
    margin-bottom: 128px;

    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @media (max-width: 560px) {
      margin-left: initial;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  &_title {
    font-family: 'Merriweather', serif;
    font-size: 58px;
    line-height: 84px;
    letter-spacing: -0.815625px;
    font-weight: $black;

    margin-bottom: 45px;

    @media (max-width: 1180px) {
      font-size: 50px;
      line-height: 60px;
    }

    @media (max-width: 560px) {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.225px;
      text-align: center;
    }
  }

  &_company {
    cursor: pointer;
    display: flex;
    align-items: center;

    margin-bottom: 45px;

    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      opacity: 0.5;
    }
    
    @media (max-width: 560px) {
      flex-direction: column;
      margin-bottom: 15px;
    }
  }

  &_company * {
    font-family: 'Merriweather', serif;
  }

  &_company_logo {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 50px;
    font-weight: $bold;
    color: var(--dark);

    width: 100%;
    max-width: 80px;
    height: 80px;
    margin-right: 20px;

    box-shadow: 0px 2px 16px rgba(204, 188, 252, 0.15);
    background-color: var(--white);
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 1180px) {
      width: 60px;
      height: 60px;
      font-size: 35px;
    }

    @media (max-width: 560px) {
      margin-right: initial;
      width: 40px;
      height: 40px;
      margin-bottom: 11px;

      font-size: 30px;
    }
  }

  &_company_name {
    font-weight: $black;
    font-size: 30px;
    line-height: 40px;
    color: rgba(255, 199, 38, 1);
    letter-spacing: -0.421875px;

    @media (max-width: 1180px) {
      font-size: 32px;
    }

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.196875px;
    }
  }

  &_descr {
    font-weight: $medium;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3375px;

    margin-bottom: 30px;

    @media (max-width: 1180px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: 560px) {
      display: none;
    }
  }

  &_descr_sent {
    font-weight: $medium;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3375px;

    margin-bottom: 30px;
    
    @media (max-width: 560px) {
      font-weight: $medium;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.16875px;
      text-align: center;

      margin-bottom: 30px;
    }
  }

  &_learnmore {
    max-width: 420px;

    font-weight: $regular;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.28125px;

    margin-bottom: 45px;

    @media (max-width: 1180px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: 560px) {
      display: none;
    }
  }

  &_learnmore_link {
    font-weight: $medium;
    color: rgba(255, 199, 38, 1);
    margin-right: 3px;
  }

  &_btn {
    width: 238px;
    height: 56px;
    background-color: rgba(255, 199, 38, 1);

    border-radius: 28px;
    border: none;

    color: rgba(114, 51, 204, 1);
    font-weight: $demibold;
    font-size: 23px;
    line-height: 34px;

    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(255, 199, 38, 0.5);
    }
    
    @media (max-width: 560px) {
      font-size: 16px;
      line-height: 24px;

      display: block;
      margin: 0 auto;
    }
  }
}