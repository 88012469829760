@import 'styles/vars';

.modal * {
  font-family: 'Poppins', sans-serif;
}

.modal {
  max-width: 896px !important;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: calc(80vh - 95px);

    border-radius: 19px;
    border: none;
    background: #fff;
    border: none;
    outline: none;

    @media (max-width: 560px) {
      height: initial;
      padding: 60px 20px 24px 20px;
    }
  }

  
  &__title {
    font-weight: $medium;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3375px;

    margin-bottom: 30px;

    color: rgba(0, 0, 0, 0.85);

    @media (max-width: 560px) {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.16875px;
      margin-bottom: initial;
    }
  }

  &__options {
    margin-bottom: 25px;

    color: $font-main-color;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.28125px;

    @media (max-width: 560px) {
      font-size: 10px;
      line-height: 18px;
      letter-spacing: -0.140625px;
      margin-bottom: initial;
    }
  }

  &__quantity {
    margin-bottom: 45px;

    color: $font-main-color;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.3375px;

    @media (max-width: 560px) {
      font-weight: $regular;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.16875px;

      margin-bottom: 20px;

      span {
        font-weight: $bold;
      }
    }
  }

  &__input_container {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 100px;

    margin-bottom: 40px;

    @media (max-width: 560px) {
      width: 240px;
      height: 40px;

      margin-bottom: 20px;
    }
  }

  &__input_icon {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(107, 89, 237, 1);

    font-weight: $medium;
    font-size: 40px;
    line-height: 32px;

    @media (max-width: 560px) {
      font-size: 14px;
      left: 20px;
    }
  }

  input {
    width: 100%;
    height: 100%;

    outline: none;
    
    background: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 50px;

    color: $font-main-color;
    font-weight: $medium;
    font-size: 40px;
    line-height: 30px;
    text-align: right;

    padding-right: 20px;

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 30px;
    }
  }

  input:focus {
    border-radius: 50px;
  }

  &__close_btn {
    background: transparent;
    border: none;
    outline: none;

    position: absolute;
    left: 20px;
    top: 20px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }

    @media (max-width: 560px) {
      width: 30px;
      height: 30px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__add_btn {
    outline: none;
    width: 100%;
    max-width: 500px;
    height: 100px;
    background: rgba(107, 89, 237, 1);
    border-radius: 50px;
    border: none;

    color: #fff;
    font-weight: $demibold;
    font-size: 40px;
    line-height: 60px;

    transition: all 0.3s;

    &:hover {
      background: rgba(107, 89, 237, 0.5);
    }

    @media (max-width: 560px) {
      width: 238px;
      height: 56px;

      font-weight: $bold;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__btn {
    border: none;
    background: transparent;
    padding: 0;
  
    font-weight: $medium;
    font-size: 20px;
    line-height: 30px;
    color: rgba(107, 89, 237, 1);
    letter-spacing: -0.28125px;
    transition: all 0.3s;
  
    &:hover {
      opacity: 0.5;
    }

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 560px) {
      font-weight: $demibold;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: -0.196875px;
    }
  }
}

