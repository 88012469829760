@import 'styles/vars'; 

.footer {
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.5px;

  &__title {
    font-weight: $regular;
  }

  &__title_name {
    display: block;
    font-weight: $demibold;
  }
}

.footer__form {
  max-width: 284px;
  margin: 20px auto 0 auto;

  & .input_append {
    width: 40%;
  }

  & .form_control, & .custom_btn {
    border-radius: 50rem;
    height: 41px;
  }

  & .form_control {
    font-weight: $regular;
    font-size: 12px;
    line-height: 14px;
    color: $font-color-gray;
    letter-spacing: -0.5px;

    padding-left: 30px;
  }

  & .custom_btn {
    width: 100%;
    background-color: rgba(74, 74, 74, 1);

    font-weight: $bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.5px;
    text-transform: uppercase;
  }
}