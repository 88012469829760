@import 'styles/vars';

.items_container {
  font-size: 10px;
  line-height: 14px;
  font-weight: $regular;
  letter-spacing: -0.416667px;

  margin-top: 25px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  padding-bottom: 14px;
}