@import 'styles/vars';

.title {
  font-weight: $bold;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: initial;
  color: $font-main-color;
  letter-spacing: -0.5px;

  &__uppercase {
    text-transform: uppercase;
  }

  &__right {
    text-align: right;
  }
}