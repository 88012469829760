@import "styles/vars";

.badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 75px;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 0;
    height: 0;
    border-top: 75px solid #4A4A4A;
    border-left: 80px solid transparent;
  }

  &__logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    right: 0;
    z-index: 5;
    transform: rotate(42deg);
    top: 10px;
  }

  &__text {
    font-weight: $bold;
    font-size: 6px;
    color: var(--white);
    margin-bottom: 3px;
  }
}

.header {
  position: relative;
  padding: 0 0 15px 0;
  justify-content: center;
  align-items: center;
  color: $font-main-color;

  &__title {
    font-weight: $bold;
    font-size: 16px;
    letter-spacing: 0.137143px;
    line-height: 22px;
    margin-bottom: initial;
    text-transform: uppercase;
  }

  &__logo {
    position: absolute;
    left: 15px;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: $bold;
    background-color: var(--white);
    color: var(--dark);

    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    border-radius: 100%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.sender {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  line-height: 18px;
  font-weight: $demibold;
  letter-spacing: -0.5px;
  color: $font-main-color;
  
  margin-top: 30px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  padding-bottom: 30px;

  &__contacts, &__address {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__contacts {
    text-align: left;

    &_item:first-child {
      font-weight: $bold;
    }
  }

  &__address {
    text-align: right;
    max-width: 130px;
  }
}

.customer {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  line-height: 18px;
  font-weight: $regular;
  letter-spacing: -0.5px;
  color: $font-main-color;

  margin-top: 22px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  padding-bottom: 30px;

  &__contacts {
    max-width: 140px;
  }

  &__contacts, &__details {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__details {
    text-transform: uppercase;
    text-align: right;
  }
}
