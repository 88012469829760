@import 'styles/vars';

.container {
  margin-top: 20px;
}

.taxes {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}

.row {
  display: flex;
  justify-content: space-between;

  font-weight: $medium;
  font-size: 12px;
  line-height: 14px;
  color: $font-color-gray;
  letter-spacing: -0.5px;

  &__title, &__price {
    margin: 4px 0 0 0;
  }

  &__price {
    color: $font-main-color;
    font-weight: $bold;
  }
}

.subtotal {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
}

.subtotal_row {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  line-height: 14px;
  color: $font-color-gray;
  letter-spacing: -0.5px;
  font-weight: $demibold;

  &__title, &__price {
    margin: 4px 0 0 0;

    &:first-child {
      margin: 0;
    }
  }

  &__title {
    line-height: 16px;
  }

  &__price {
    line-height: 14px;
  }

  &:first-child > span {
    color: $font-main-color;
  }
}

.total {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  color: $font-main-color;
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);

  font-weight: $bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.583333px;

  &__title {
    font-weight: $extra-bold;
    margin: 0;
    text-transform: uppercase;
    line-height: 19px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 1px;
    width: 100%;
    background: rgba(151, 151, 151, 0.5);
    height: 1px;
    left: 0;
  }
}