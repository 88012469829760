@import 'styles/vars';

.stripe__connect, .stripe__connect * {
  font-family: 'Poppins', sans-serif;
}

.stripe__connect {
  &_container {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.16875px;
    text-align: center;
    color: var(--white);

    padding-top: 100px;
    height: 100vh;
    background: linear-gradient(180deg, rgba(167, 76, 242, 1) 22.2%, rgba(117, 101, 251, 1) 98.61%), rgba(216, 216, 216, 1);
  }

  &_images {
    display: flex;
    justify-content: space-evenly;

    margin-bottom: 30px;
  }

  &_img {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
    width: 71px;
    height: 60px;
    background: var(--white);

    img {
      width: 46px;
      height: 22px;
    }
  }

  &_title {
    font-weight: $black;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Merriweather', serif;

    margin-bottom: 20px;
  }

  &_subtitle {
    font-weight: $medium;
    font-size: 12px;

    max-width: 120px;
    margin: 0 auto 20px auto;
  }

  &_text {
    font-weight: $thin;

    max-width: 280px;
    margin: 0 auto 40px auto;
  }
}