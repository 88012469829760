@import 'styles/vars';

.container {
  padding: 20px 20px 23px 20px;
  background: linear-gradient(180deg, rgba(167, 76, 242, 1) 22.2%, rgba(117, 101, 251, 1) 98.61%), rgba(216, 216, 216, 1);
  min-height: calc(100vh - 60px);

  color: var(--white);
}

.company_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  font-size: 50px;
  font-weight: $bold;
  color: var(--dark);

  width: 100%;
  max-width: 80px;
  height: 80px;

  box-shadow: 0px 2px 16px rgba(204, 188, 252, 0.15);
  background-color: var(--white);
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  span {
    text-transform: uppercase;
  }

  @media (max-width: 1180px) {
    width: 60px;
    height: 60px;
    font-size: 35px;
  }

  @media (max-width: 560px) {
    width: 30px;
    height: 30px;
    margin-bottom: 11px;

    font-size: 30px;
  }
}

.company_name {
  margin-bottom: 20px;

  @media (max-width: 560px) {
    text-align: center;
    font-weight: $bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.196875px;
  }
}

.invoice {
  margin-bottom: 10px;

  @media (max-width: 560px) {
    text-align: center;

    font-weight: $thin;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.16875px;
  }
}

.price__wrapper {
  padding: 20px;
  margin-bottom: 20px;

  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(232, 247, 255, 1);
  box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.0803103);
  border-radius: 3px;

  color: $font-main-color;

  &_row {
    display: flex;
    justify-content: space-between;

    

    @media (max-width: 560px) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.16875px;

      color: rgba(0, 0, 0, 0.85);
      font-weight: $regular;
    }
  }

  &_price {
    @media (max-width: 560px) {
      font-weight: $demibold;
    }
  }

  &_total {
    display: flex;
    justify-content: space-between;

    color: rgba(107, 89, 237, 1);

    @media (max-width: 560px) {
      font-weight: $bold;

      font-size: 12px;
      line-height: 32px;
      letter-spacing: -0.16875px;
    }
  }

  &_total_black {
    display: flex;
    justify-content: space-between;

    color: rgba(0, 0, 0, 1);

    @media (max-width: 560px) {
      font-weight: $bold;

      font-size: 12px;
      line-height: 32px;
      letter-spacing: -0.16875px;
    }
  }

  &_instalments {
    display: flex;
    justify-content: space-between;

    color: rgba(107, 89, 237, 1);

    span:nth-child(2) {
      font-style: italic;
    }

    @media (max-width: 560px) {
      font-weight: $bold;

      font-size: 12px;
      line-height: 32px;
      letter-spacing: -0.16875px;
    }
  }
}

.paynow_btn {
  display: block;
  margin: 0 auto;
  width: 238px;
  height: 56px;
  background: transparent;
  border: 1px solid rgba(255, 199, 38, 1);
  border-radius: 28px;

  color: rgba(255, 199, 38, 1);
  transition: all 0.3s;

  @media (max-width: 560px) {
    font-weight: $demibold;
    font-size: 14px;
    line-height: 21px;
  }

  &:hover {
    opacity: 0.6;
  }
}