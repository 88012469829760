@import 'styles/vars';

.schedule {
  padding: 10px 0 10px 0;
  
  &__title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: $bold;
    line-height: 14px;
    color: $font-main-color;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
  }

  &__col, &__col_paid {
    display: flex;
    flex-direction: column;
    font-weight: $regular;
    padding: 0 0 28px 16px;
    text-align: center;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  &__col:first-child, &__col_paid:first-child  {
    position: relative;
    
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 5px;

    &::before {
      border-radius: 100%;
      content: '';
      width: 11px;
      height: 11px;
      background-color: rgba(74, 74, 74, 1);
      position: absolute;
      top: 1px;
      left: -6px;
    }
  }

  &__col_paid:first-child {
    &::before {
      background-color: rgba(155, 155, 155, 1);
    }

    .schedule__amount {
      color: $font-color-gray;
      font-weight: $bold;
    }
  }

  &__date {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 3px;
  }

  &__amount {
    color: $font-main-color;
    font-weight: $bold;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }

  &__method {
    color: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin-top: 3px;

    @media (max-width: 768px) {
      margin-right: initial;
      margin-top: 4px;
    }
  }

  &__status_paid, &__status_unpaid {
    display: flex;
    align-items: center;
    justify-content: center;

    
    border-radius: 12px;
    min-width: 84px;
    height: 24px;
    
    font-weight: $demibold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--white);
  }

  &__status_unpaid {
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0.85);
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.45);
    }
  }

  &__status_paid {
    background-color: rgba(155, 155, 155, 1);
  }
}