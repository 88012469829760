@import 'styles/vars';

.quote__item {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 20px;
  }

  line-height: 32px;

  @media (max-width: 768px) {
    grid-template-columns: 2fr 1fr 1fr;
    padding: 0 20px;
  }

  @media (max-width: 560px) {
    grid-template-columns: 3fr 1fr;
    grid-template-rows: repeat(2, 2fr);
    border-top: 1px solid rgba(151, 151, 151, 0.2);
    padding: 20px 0 0 0;
    margin-bottom: initial;
  }

  &_info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: $regular;
    font-size: 24px;
    letter-spacing: -0.3375px;
    color: rgba(0, 0, 0, 0.85);

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 560px) {
      font-weight: $medium;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.16875px;
    }
  }

  &_info_wrapper {
    display: flex;
    align-items: center;
  }

  &_infotext {
    max-width: 400px;
  }

  &_indent {
    width: 110px;
    height: 100px;
    margin-right: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid rgba(135, 135, 135, 1);
    border-radius: 4px;

    color: $font-color-gray;
    font-size: 40px;
    font-weight: $extra-bold;
    letter-spacing: -0.5625px;

    @media (max-width: 768px) {
      width: 90px;
      height: 80px;
    }

    @media (max-width: 560px) {
      display: none;
    }
  }

  &_title {
    line-height: 32px;
    font-weight: $medium;
    font-size: 24px;

    @media (max-width: 768px) {
      line-height: 24px;
      font-size: 18px;
    }

    @media (max-width: 560px) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.16875px;
    }
  }

  &_options {
    margin-left: 140px;
    margin-top: 10px;

    font-weight: $regular;
    color: $font-main-color;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.28125px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 18px;

      margin-left: 120px;
    }

    @media (max-width: 560px) {
      margin-left: initial;
      margin-top: 10px;

      font-size: 10px;
      letter-spacing: -0.140625px;
    }
  }

  &_col {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 560px) {
      align-items: flex-end;
    }
  }

  &_col_price {
    @media (max-width: 560px) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      grid-column: 1 / 3;
    }
  }

  &_col_text {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 560px) {
      align-items: flex-end;
    }
  }

  &_col_title {
    margin-bottom: 8px;

    font-size: 18px;
    line-height: 30px;
    font-weight: $medium;
    letter-spacing: -0.253125px;
    color: $font-main-color;

    @media (max-width: 560px) {
      font-size: 12px;
      letter-spacing: -0.16875px;

      line-height: 20px;
      margin-bottom: initial;
    }
  }

  &_quantity, &_total {
    font-weight: $bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3375px;
    color: rgba(0, 0, 0, 0.85);

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.196875px;
    }
  }

  &_price {
    font-weight: $thin;
    font-size: 16px;
    line-height: 30px;
    color: $font-main-color;
    letter-spacing: -0.225px;

    @media (max-width: 560px) {
      font-weight: $regular;
      font-size: 10px;
      letter-spacing: -0.140625px;

      line-height: 20px;
    }
  }

  &_btn {
    border: none;
    background: transparent;

    font-weight: $medium;
    font-size: 20px;
    line-height: 30px;
    color: rgba(107, 89, 237, 1);
    letter-spacing: -0.28125px;
    transition: all 0.3s;

    &:hover {
      opacity: 0.5;
    }
  }
}