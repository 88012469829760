@import 'styles/vars';

.container {
  position: relative;
  border: 1px solid rgba(151, 151, 151, 0.35);
  max-width: 768px !important;
  padding: 48px 40px 39px 40px !important;

  @media (max-width: 768px) {
    padding-right: 20px !important;
    padding-left: 20px !important;
    overflow: hidden;
  }
}
