@import 'styles/vars';

.yellow_round_btn {
  width: 238px;
  height: 56px;
  background-color: rgba(255, 199, 38, 1);

  border-radius: 28px;
  border: none;

  color: rgba(114, 51, 204, 1);
  font-weight: $demibold;
  font-size: 23px;
  line-height: 34px;

  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 199, 38, 0.5);
  }
  
  @media (max-width: 560px) {
    font-size: 16px;
    line-height: 24px;

    display: block;
    margin: 0 auto;
  }
}