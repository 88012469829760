@import 'styles/vars';

.totaldue_btn {
  display: block;
  margin: 20px auto 0 auto;
  border: none;

  width: 238px;
  height: 56px;
  background: rgba(107, 89, 237, 1);

  color: var(--white);
  font-weight: $bold;
  font-size: 16px;
  line-height: 24px;
  border-radius: 28px;

  &:disabled {
    opacity: 0.3;
  }
}