@import 'styles/vars';

.container {
  display: flex;
  flex-direction: column;
}

.accordion_container {
  display: flex;
  flex-direction: column;
  max-height: 0px;
  overflow: hidden;
  padding-bottom: 15px;

  transition: max-height 0.5s ease-in-out;

  &__open {
    max-height: 1000px;
  }
}

.method__card {
  height: 60px;
  margin-bottom: 20px;

  input:checked + label {
    border: 1px solid rgba(107, 89, 237, 1);
  }

  input:checked + label:after {
    display: block;
  }

  input:checked + label:before {
    background: rgba(107, 89, 237, 1);
  }

  input:checked + label &_title {
    color: rgba(107, 89, 237, 1);
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &_label {
    height: 100%;

    position: relative;
    padding: 0 20px 0 40px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 1px solid rgba(188, 193, 200, 1);
    border-radius: 5px;

    &::before {
      content: '';
      top: 50%;
      position: absolute;
      left: 10px;
      transform: translateY(-50%);
  
      width: 19px;
      height: 19px;
  
      background: #FFFFFF;
      border: 1px solid rgba(226, 209, 249, 1);
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.164582);
      border-radius: 100%;
    }
  
    &::after {
      content: '';
      display: none;
      top: 50%;
      position: absolute;
      left: 16px;
      transform: translateY(-50%);
  
      width: 7px;
      height: 7px;
      background: #FFFFFF;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.164582);
      border-radius: 50%;
    }
  }

  &_title {
    font-weight: $bold;
    font-size: 13px;
    line-height: 19px;

    color: #000000;
  }

}

.accordion_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 40px auto;

  border: none;
  background: transparent;
  color: rgba(65, 29, 117, 1);

  font-weight: $demibold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.16875px;

  span {
    margin-bottom: 12px;
  }

  &_icon {
    transform: rotate(180deg);
    transition: all 0.3s ease-in;
  }

  &_icon_more {
    transform: rotate(0deg);
    transition: all 0.3s ease-in;
  }

  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }
}
