@import 'styles/vars';

.pricing__items {
  padding: 55px 0 48px 0;

  @media (max-width: 560px) {
    padding: 20px 0;
  }
  
  &_header {
    text-align: center;
    letter-spacing: -0.28px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
    line-height: 34px;
    font-weight: $thin;

    @media (max-width: 1180px) {
      font-size: 16px;

      line-height: 24px;
    }

    @media (max-width: 560px) {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: -0.14px;
    }
  }

  &_title {
    font-size: 50px;
    font-weight: $bold;
    letter-spacing: -0.703125px;

    @media (max-width: 1180px) {
      font-size: 40px;
    }

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &_subtitle {
    font-weight: $medium;
  }

  &_container {
    max-width: 920px;
    margin: 90px auto 0 auto;

    border-bottom: 1px solid rgba(151, 151, 151, 0.5);

    @media (max-width: 560px) {
      margin: 21px auto 0 auto;
      border-bottom: none;
    }
  }

  &_totals {
    max-width: 920px;
    margin: 0 auto;

    border-bottom: 1px solid rgba(151, 151, 151, 0.5);

    font-size: 20px;
    line-height: 30px;
    font-weight: $medium;
    letter-spacing: -0.28125px;
    color: $font-main-color;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 560px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      border-top: 1px solid rgba(151, 151, 151, 0.5);
      margin: 20px -20px 0 -20px;

      font-size: 12px;
      line-height: 30px;
      letter-spacing: -0.16875px;
    }
  }

  &_totals_row, &_total {
    width: 85%;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;

    @media (max-width: 768px) {
      width: 100%;
      padding-right: initial;
      padding: 0 20px;
    }

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  &_totals_row {
    margin: 20px 0 20px auto;
    @media (max-width: 560px) {
      margin: 0;
      &:first-child {
        margin-top: 20px;
      }

      &:nth-child(2) {
        margin-bottom: 20px;
      }
    }
  }

  &_total {
    margin-left: auto;
    padding: 20px 10px 20px 0;
    border-top: 1px solid rgba(151, 151, 151, 0.5);

    font-weight: $bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3375px;
    color: rgba(0, 0, 0, 0.85);

    @media (max-width: 768px) {
      font-size: 18px;
      width: 100%;
      padding: 20px 20px;
    }

    @media (max-width: 560px) {
      font-size: 14px;
      line-height: 30px;
      letter-spacing: -0.196875px;
    }
  }
}