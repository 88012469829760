@import 'styles/vars';

.quote__pricing, .quote__pricing * {
  font-family: 'Poppins', sans-serif;
}

.quote__pricing {
  &_container_sent {
    width: 100%;
    height: 100vh;
  }

  &_container {
    margin: 0 auto;
    max-width: 1440px;
    padding-bottom: 48px;

    background-color: rgba(247, 247, 247, 1);

    @media (max-width: 560px) {
      padding: 0 20px 20px 20px;
    }
  }

  &_send_btn {
    display: block;
    border: none;

    width: 100%;
    max-width: 490px;
    height: 100px;
    margin: 0 auto;

    font-weight: $demibold;
    font-size: 40px;
    line-height: 60px;
    color: var(--white);

    background: rgba(107, 89, 237, 1);
    border-radius: 50px;

    transition: all 0.3s;

    &:disabled {
      opacity: 0.3;
    }

    &:hover:not(:disabled) {
      background: rgba(107, 89, 237, 0.5);
    }

    @media (max-width: 560px) {
      width: 238px;
      height: 56px;

      font-size: 16px;
      line-height: 24px;
    }
  }
}