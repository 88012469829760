@import 'styles/vars';

.checkout_page, .checkout_page * {
  font-family: "Poppins", sans-serif;
}

.payment {
  background: rgba(247, 247, 247, 1);
  padding: 20px;

  hr {
    margin: 0;
  }

  &__title {
    text-align: center;
    color: rgba(0, 0, 0, 0.85);

    margin-bottom: 40px;

    @media (max-width: 560px) {
      font-weight: $bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.196875px;
    }
  }

  &__text {
    color: $font-main-color;
    text-align: center;

    margin-bottom: 10px;

    @media (max-width: 560px) {
      font-weight: $regular;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: -0.140625px;
    }
  }

  &__icon {
    display: block;
    margin: 0 auto 20px auto;
  }
}