@import 'styles/vars';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 20px 23px 20px;
  background: linear-gradient(180deg, rgba(167, 76, 242, 1) 22.2%, rgba(117, 101, 251, 1) 98.61%), rgba(216, 216, 216, 1);
  min-height: calc(100vh - 60px);

  color: var(--white);
}

.title__success {
  margin-top: 5px;

  font-family: 'Merriweather', serif;
  font-weight: $black;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.225px;
}

.title__section {
  font-weight: $thin;
  font-size: 12px;
  line-height: 14px;
  color: rgba(226, 209, 249, 1);
  letter-spacing: -0.16875px;
  text-transform: uppercase;

  margin-bottom: 10px;
}

.subtitle__section {
  font-weight: $bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.196875px;
  color: rgba(226, 209, 249, 1);

  margin-bottom: 10px;
}

.text__method {
  color: rgba(226, 209, 249, 1);

  font-weight: $thin;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.16875px;
}

.text {
  color: rgba(226, 209, 249, 1);

  font-weight: $thin;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.14px;
}

.company_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  font-size: 50px;
  font-weight: $bold;
  color: var(--dark);

  width: 100%;
  max-width: 80px;
  height: 80px;

  box-shadow: 0px 2px 16px rgba(204, 188, 252, 0.15);
  background-color: var(--white);
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  span {
    text-transform: uppercase;
  }

  @media (max-width: 1180px) {
    width: 60px;
    height: 60px;
    font-size: 35px;
  }

  @media (max-width: 560px) {
    width: 30px;
    height: 30px;
    margin-bottom: 11px;

    font-size: 30px;
  }
}

.company_name {
  margin-bottom: 20px;

  @media (max-width: 560px) {
    text-align: center;
    font-weight: $bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.196875px;
  }
}

.invoice {
  margin-bottom: 10px;

  @media (max-width: 560px) {
    text-align: center;

    font-weight: $thin;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.16875px;
  }
}

.divider {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}