@import 'styles/vars';

.container {
  padding: 20px 0 0 0;
}

.payment__title {
  font-weight: $bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.583333px;
  text-transform: uppercase;

  margin: 0;
  color: $font-main-color;
}

.payment__row {
  &:nth-child(3) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  }
}

.payment__row, .status__row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  letter-spacing: -0.5px;
  font-weight: $regular;
  font-size: 12px;
  line-height: 14px;
  color: $font-color-gray;

  &_title {
    margin-top: 8px;
    margin-bottom: 0;
  }

  &_subtitle {
    font-weight: $demibold;
    max-width: 122px;
    text-align: right;
  }
}

.notes {
  background-color: rgba(0, 0, 0, 0.11);
  padding: 20px 40px 20px 40px;

  margin: 20px -40px;

  &__text {
    font-weight: $regular;
    font-size: 12px;
    line-height: 18px;
    color: $font-main-color;
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    padding: 20px;
    margin: 14px -20px;
  }
}

.status {
  &__paid {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(155, 155, 155, 1);
    border-radius: 12px;
    min-width: 84px;
    height: 24px;
    
    font-weight: $demibold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--white);
  }

  &__unpaid {
    background-color: rgba(74, 74, 74, 1);
  }

  &__title {
    font-weight: $bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.5px;
    color: $font-main-color;
    margin-bottom: 8px;
  }
}

.balance {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(74, 74, 74, 1), rgba(74, 74, 74, 1));

  margin: 20px -40px;
  padding: 12px 40px;

  color: var(--white);
  font-weight: $extra-bold;
  font-size: 14px;
  line-height: 18px;

  &__title {
    text-transform: uppercase;
    margin: 0;
  }
}

.button__block {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  padding-bottom: 20px;
  margin: 0 -40px 20px -40px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.accept_btn, .reject_btn {
  width: 137px;
  height: 40px;
  margin-right: 10px;

  border: 1px solid rgba(74, 74, 74, 1);
  border-radius: 30px;

  text-transform: uppercase;
  font-weight: $extra-bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.583333px;
  transition: background 0.3s;
}

.reject_btn {
  color: rgba(0, 0, 0, 0.85);

  background: rgba(255, 255, 255, 1);

  &:hover {
    background: rgba(236, 236, 236, 1);
  }
}

.accept_btn {
  color: var(--white);

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)), rgba(74, 74, 74, 1);

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), rgba(74, 74, 74, 1);
  }
}